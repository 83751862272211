import { Pipe, PipeTransform } from '@angular/core';
import { replaceAll } from '@element451-libs/utils451/helpers';
import { keys, reduce } from 'lodash';

export const replacePlaceholders = (
  value: string,
  placeholders: any = null
): string => {
  if (!value) return value;
  if (!placeholders) return value;

  return reduce(
    keys(placeholders),
    (replaced, key) => replaceAll(replaced, key, placeholders[key]),
    value
  );
};

@Pipe({ name: 'elmPlaceholder' })
export class PlaceholderPipe implements PipeTransform {
  transform(
    value: string,
    placeholders: { [key: string]: string } | null = null
  ): string {
    return replacePlaceholders(value, placeholders);
  }
}
