import { ActivityApi } from '../activity';
import { AddressApi } from '../address';
import { Decision451Api } from '../decision451';
import { EventsApi } from '../events';
import { FormsApi } from '../forms';
import { LabelsApi } from '../labels';
import { MilestonesApi } from '../milestones';
import { NotesApi } from '../notes';
import { PhoneApi } from '../phone';
import { TaxonomiesApi } from '../taxonomies';
import { TerritoriesApi } from '../territories';
import { WorkflowsApi } from '../workflows';

export enum ProfileType {
  agent = 'agent',
  family = 'family',
  influencer = 'influencer',
  volunteer = 'volunteer',
  organization = 'organization',
  student = 'student'
}

export enum UserType {
  external = 'external',
  internal = 'internal',
  team = 'team',
  system = 'system'
}

export interface User {
  id: string | null;
  email: string | null;
  owner?: string | null;
  territory?: string | null;
  first_name: string | null;
  last_name: string | null;
  middle_name?: string;
  preferred_name?: string;
  former_last_name?: string;
  updated_at?: string | null;
  created_at?: string | null;
  phone?: {
    type?: string | null;
    number?: string | null;
    country_code?: string | null;
    international?: string | null;
    valid?: boolean;
    carrier_name?: string;
  }[];
  addresses?: AddressApi.Address[];
  identities?: { type: UserIdentityTypes; value: string }[];
  type: UserType | null;
  profile_type: ProfileType | null;
  active: boolean | null;
  labels?: string[];
  date_of_birth?: string;
  custom?: object;
  calculated?: object;
  milestones?: any[];
  citizenship?: object;
  education?: object;
  territory_manual_updates?: boolean;
  // old
  location: {
    city: string | null;
    country: string | null;
    state: string | null;
    street_1: string | null;
    zipcode: string | null;
  };
}

export type UserDataBySlugs = UserDataBySlugsItem[];

export interface UserDataBySlugsItem {
  user_id: string;
  data: {
    user_data: {
      [slug: string]: any;
    };
  }[];
}

export interface UserLabel {
  guid: string;
  name: string;
  slug: string;
  color: string;
}

export enum ProfileEditableField {
  EmailAddress = 'user-email-address',
  Password = 'user-password',
  FirstName = 'user-first-name',
  LastName = 'user-last-name',
  Dob = 'user-dob',
  Term = 'user-term',
  Gender = 'user-gender',
  Groups = 'user-groups',
  Territory = 'user-territory',
  TerritoryManualUpdates = 'user-territory-manual-updates',
  ProfileType = 'user-profile-type'
}

export interface ProfileField {
  slug: ProfileEditableField;
  name: string;
  value: string | string[] | ProfileType | boolean;
}

export interface UserLocation {
  city: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  country: string;
  state: string;
  street_1: string;
  zipcode: string;
}

export interface UserAvatar {
  guid: string;
  url: string;
  transformations?: {
    image_32x32_fit?: { url: string };
    image_150x150_fit?: { url: string };
    image_500x500_fit?: { url: string };
  };
}

export interface Person {
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  preferred_name?: string | null;
  former_last_name?: string | null;
  avatar?: UserAvatar;
}

export interface Author extends Person {
  id: string;
}

export interface ApiPerson extends Person {
  _id: string;
}

export interface UserTOS {
  accepted: boolean;
  accepted_at?: string;
  version: string;
}

export interface Slug {
  slug: string;
  scope?: { [value: string]: string };
}

export const SYSTEM_USER: Person = {
  first_name: 'Element451',
  last_name: 'System'
};

export interface UserSourceProperties {
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
  application_guid: string;
  application_name: string;
  major: string;
  term: string;
}

export type UserSourceType =
  | 'WEB'
  | 'APP'
  | 'CUSTOM'
  | 'EVENT'
  | 'SURVEY'
  | 'DASHBOARD'
  | 'SEARCH';

export interface UserSource {
  type: UserSourceType;
  timestamp: string; // ISO string in UTC timezone - (new Date()).toISOString()
  url: string;
  properties: Partial<UserSourceProperties>;
  name?: 'Application Registration' | string; // TODO: define names
}

export interface UserRegisterItem {
  fields: FormsApi.FieldValue[];
  source: UserSource;
  groups?: string[]; // array of group hashes (defaulted on the API to applicant group)
}

export interface InformationRequest {
  guid: string;
  title: string;
  description: string;
  description_optional: string;
  items: any[];
  sidebar_content: {
    title: string;
    info_blocks: InformationBlock[];
  };
  user_info_requests: UserInfoRequest[];
  student_forms: RequestForm[];
  request_types: RequestType[];
}

export interface InformationBlock {
  title: string;
  markdown: boolean;
  description: string;
}

export interface UserInfoRequest {
  _id: string;
  email: string;
  first_name: string;
  last_name: string;
  status: 'pending'; // TODO: add more status types
  application_guid: string;
  _info_request_guid: string;
  _form_send_to_guid: string;
  _form_request_info_guid: string;
  submitted_at: string;
  request_type_guid: string;
  waive: boolean;
  _locker_key: string;
  multipurpose: boolean;
  job_title: string;
  recommender_email: string;
  phone: {
    'phone-type': 'home';
    'phone-country_alpha_2': string;
    'phone-country_code': string;
    'phone-number': string;
  };
}

export interface RequestForm {
  guid: string;
  name: string;
  segment: number;
  _form: string;
  notification_guid: {
    with_waiver: string;
    without_waiver: string;
  };
  description: string;
  waiver_label: string;
  preview_description: string;
  preview_description_success: string;
  request_type_guid: string;
  fields: FormsApi.FieldValue[];
}

export interface RequestType {
  guid: string;
  name: string;
}

export interface BasicInformationForm {
  basic_information: FormsApi.FieldValue[];
  address_information: FormsApi.FieldValue[];
}

export interface BasicInformationFormField {
  name: string;
  value?: string;
  subfields?: { name: string; value: string }[];
}

export interface BasicInformationItem {
  form: string;
  fields: BasicInformationFormField[];
}

// Replace Tokens

export interface TokenReplacePayload {
  token: string;
  context: {
    user_id?: string[];
    [key: string]: string | any;
  };
}

export interface TokenReplaceResponsePayload extends TokenReplacePayload {
  value: string;
}

export interface TokenReplaceResponse {
  tokens: TokenReplaceResponsePayload[];
}

export interface RegisterUserResponse {
  done: boolean;
  registration_id: string;
  token?: string;
}

export interface LetterLockerResponse {
  clientToken: string; // "tu",
  userId: string; // "595422b451509715c9799ae6",
  registrationId: string; // "C4A1F193",
  applicationGuid: string; // "tu.applications.131",
  decisionId: string; // "5abbdd88d86f1a0f05290f1a",
  letters: string[];
}

export enum Form {
  Basic = 'basic_information',
  Academic = 'academic_information',
  Family = 'family',
  Contact = 'contact_information',
  Personal = 'personal_information',
  Citizenship = 'citizenship',
  /**
   * education_schools_information is used on create user form,
   **/
  EducationSchools = 'education_schools_information',
  /**
   * Education is an edge case, is used when fetching profile sections instead of education_schools_information
   **/
  Education = 'education',
  Sources = 'sources',
  Groups = 'groups',
  Milestones = 'milestones',
  Employment = 'employment',
  Identities = 'identities',
  Activities = 'activities',
  TestScores = 'test_scores',
  Additional = 'additional_information',
  Athletics = 'athletics',
  Gpa = 'gpa'
}

export const enum ProfileForm {
  ID = 'virtual.form.profile'
}

export type CreateUserForm = { [formId in Form]: UserForm };

export interface UserForm {
  form_data: {
    fields: FormsApi.Field[];
    guid: string;
    _id: string;
  };
}

export interface ProfileSection extends UserForm {
  user_data: any;
}

export interface BasicInformationProfileSection {
  [Form.Personal]: ProfileSection;
  [Form.Citizenship]: ProfileSection;
  [Form.Contact]: ProfileSection;
}

interface FormFieldValue {
  slug: string;
  name: string;
  value: string | { checked: boolean; value: string }[];
}

export interface FieldResponseValue {
  name: string;
  value: any;
  subfields?: FieldResponseValue[];
  weight?: number;
}

interface FormValue {
  form: ProfileForm.ID;
  fields: FormFieldValue | (FormFieldValue & { subfields: FormFieldValue[] })[];
}

export interface CreateUserPayload {
  sections: {
    [Form.Academic]: FormValue;
    [Form.Contact]: FormValue;
    [Form.Personal]: FormValue;
    [Form.EducationSchools]: FormValue;
    [Form.Groups]: FormValue;
    [Form.Milestones]: FormValue;
    [Form.Sources]: FormValue;
  };
}

export const enum StudentType {
  Freshman = 'Freshman',
  Sophmore = 'Sophmore',
  Senior = 'Senior',
  Junior = 'Junior'
}

export interface ProfileRelationship {
  relation: Pick<TaxonomiesApi.Term, 'guid' | 'name' | 'value'>;
  target: ApiPerson;
  is_targeted: boolean;
}

export interface UserProfile {
  id: string;
  email: string;
  prefix?: string;
  first_name: string;
  middle_name?: string;
  preferred_name?: string;
  last_name: string;
  gender: string; // 'M' | 'F' | 'Male' | 'Female'
  active: boolean;
  date_of_birth: string; // '06/28/1998';
  addresses?: AddressApi.Address[];
  phone?: PhoneApi.Phone[];
  creation_date: string;
  last_update_date: string;
  last_login_date: string;
  social_security_number: string; // '123456789';
  labels: LabelsApi.Label[];
  milestones: MilestonesApi.Milestone[];
  profile_type: ProfileType;
  type: UserType;
  predictions?: UserPrediction[];
  intended_major?: {
    guid: string;
    name: string;
  };
  intended_term?: {
    guid: string;
    name: string;
  };
  intended_school?: {
    guid: string;
    name: string;
  };
  intended_degree?: {
    code: string;
    guid: string;
    name: string;
  };
  student_type: {
    label: string;
    value: string;
  };
  avatar?: UserAvatar;
  notes: NotesApi.UserNote[];
  events: EventsApi.Event[];
  workflows: WorkflowsApi.WorkflowUserStats[];
  applications: UserApplication[];
  territory: TerritoriesApi.Territory | null;
  user_relationships: ProfileRelationship[];
  owner: string | null;
  office_building?: string;
  office_room?: string;
  allow_direct_network_conn_messages?: boolean;
}

export interface UserApplication {
  guid: string; // 'tu.applications.131';
  registration_id: string; // '04CDA853';
  name: string; // 'TU Freshman Application';
  icon: string; // 'school';
  status: string; // 'started';
  registration_date: string; // '2015-10-20T08:44:52+00:00';
  submission_date: string;
  last_update_date: string; // '2015-10-20T08:44:52+00:00';
  last_login_date: string; // '2015-10-20T08:44:58+00:00';
  numeric_progress: number;
  last_export?: {
    file_url: string;
    created_at: string;
    created_by?: string;
    file?: string;
  };
  school?: {
    guid: string;
    name: string;
  };
  term?: {
    guid: string; // 'tu.terms.1';
    name: string; // 'Fall 2016'
  };
  major?: {
    guid: string;
    name: string;
  };
  degree?: {
    guid: string;
    name: string;
  };
  campus?: {
    guid: string;
    name: string;
  };
  student_type?: {
    value: string;
    label: string;
  };
  sections?: UserApplicationSection[];
  application_url?: string; // deprecated
  site_login_links: UserApplicationSiteLoginLink[]; // replaced deprecated `application_url`
  login_locker?: {
    key: string;
    namespace: string; // 'tu'
  };
  decision?: UserApplicationDecision;
}

export interface UserApplicationSection {
  name: string;
  progress_numeric: number;
  status: string;
  progress_id: string;
  subsections: Pick<
    UserApplicationSection,
    'name' | 'progress_numeric' | 'status'
  >[];
}

export interface UserApplicationDecision {
  slug: string;
  name: string;
  decision_made_at: string;
  review_starts_at: string;
  decision_id: string;
  assigned_to: Pick<
    UserProfile,
    | 'prefix'
    | 'first_name'
    | 'middle_name'
    | 'last_name'
    | 'email'
    | 'phone'
    | 'avatar'
  >;
  last_reviewer: LastReviewer;
  checklists: {
    total: number;
    completed: number;
    hidden: number;
    required: number;
    required_completed: number;
  };
  score: number;
}

export interface UserApplicationSiteLoginLink {
  name: string;
  url: string;
}

export interface LastReviewer
  extends Pick<
    UserProfile,
    | 'prefix'
    | 'first_name'
    | 'middle_name'
    | 'last_name'
    | 'email'
    | 'phone'
    | 'avatar'
  > {
  reviewed_at: string;
}

interface EventDetails {
  event_type: string;
  guid: string;
  name?: string;
}

export enum ActorType {
  Internal = 'internal',
  Workflow = 'workflow',
  Import = 'import'
}

interface ActivityBase {
  _id: string;
  action: ActivityApi.ActivityActionsUnion;
  user_id: string;
  app_id: string;
  subdom: string;
  etl_tstamp: string;
  dvce_tstamp: string;
  device_type: string;
  geo_localhour: number;
  geo_localstamp: string;
  browser_family: string;
  os_family: string;
  user_ipaddress?: string;
  updated_at: string;
  event?: EventDetails;
  event_date?: string;
}

export interface DecisionActivity {
  decision_id: string;
  registration_id: string;
  actor_id?: string;
  major?: string;
  // expanded by BE
  term?: {
    name: string;
    guid: string;
  };
  degree?: string;
  status_guid?: string;
  stage_id?: string;
  note_id?: string;
  note_body?: string;
  note_type?: Decision451Api.DecisionNoteType;
  criteria_id?: string;
  file_guid?: string;
  checklist_item_id?: string;
  checklist_item_key?: string;
  checklist_item_type?: string;
  checklist_item_status?: string;
  checklist_item_title?: string;
  checklist_item_description?: string;
  criteria_score?: number;
  cohort?: {
    active: boolean;
    _id: string;
    conditions: any[];
    title: string;
    created_at: string;
    author: string;
    index_weight: number;
  };
  score?: number;
  old_score?: number;
  assigned_to?: string | string[];
  campus?: string;
  student_type?: string;
  is_applicant?: boolean;
  // Task
  type?: string;
  name?: string;
  note?: string;
  todo?: {
    guid: string;
    name: string;
    note: string | null;
    type: string;
    _id: string;
  };
  todo_guid?: string;
  due_date?: string;
  due_time?: string;
  // Conversation
  conversation?: {
    content: {
      title: string;
    };
    guid: string;
    _id: string;
  };
  conversation_guid?: string;
  visibility?: string;
  participants?: any;
}

export interface CourseActivity {
  // expanded by BE
  course?: {
    name: string;
    guid: string;
  };
  course_guid: string;
  subject?: string;
  number?: string;
  grading?: string;
  credit?: string;
  method?: string;
  type?: string;
  academic_unit?: string;
  parent_term?: string;
  // expanded by BE
  term?: {
    name: string;
    guid: string;
  };
  start_date?: string;
  end_date?: string;
  time?: string;
  course_time?: string;
  schedule?: string;
  dropped_reason?: string;
  failed_reason?: string;
  student_grade?: string;
}

export interface UserActivity
  extends ActivityBase,
    DecisionActivity,
    CustomIntegrationEventPayload,
    CourseActivity,
    PhoneCallActivity {
  geo_coord: {
    lng: number;
    lat: number;
  };
  geo_country: string;
  geo_city: string;
  geo_timezone: string;
  geo_localoffset: number;
  geo_region?: string;
  email_guid: string;
  email_name: string;
  email_subject: string;
  evaluated_subject?: string; // "email_subject" with replaced tokens
  evaluated_content?: string; // "content" field with replaced tokens ( for SMS )
  to_name: string;
  to_email: string;
  to_number: string;
  from_number: string;
  from_name: string;
  from_email: string;
  from_domain: string;
  bounce_cat?: string;
  notification_guid: string;
  notification_name: string;
  dvce_ismobile: boolean;
  action_description: string;
  email: {
    _id: string;
    guid: string;
    from_email: string;
    from_name: string;
    subject: string;
  };
  notification: UserActivityNotification;
  element_id: string;
  application_guid: string;
  application: UserActivityApplication;
  info_request_guid: string;
  info_request: UserActivityInfoRequest;
  // Optional
  waiver?: boolean;
  survey?: {
    name: string;
    [key: string]: any;
  };
  campaign_title?: string;
  content?: string;
  page_title?: string;
  page_url?: string;
  page_referrer?: string;
  target_url?: string;
  geo_region_name?: string;
  geo_zipcode?: string;
  payment_method?: string;
  payment_successful?: boolean;
  amount?: number;
  site?: {
    name: string;
    [key: string]: any;
  };
  campaign_guid?: string;
  variant_id?: string;
  page_urlhost?: string;
  page_urlpath?: string;
  form?: {
    guid: string;
    name: string;
  };
  main_form?: boolean;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  // Profile update
  updated_fields?: {
    key: string;
    value: any;
  }[];
  // User merge
  master_name?: string;
  duplicate_name?: string;
  duplicate_id?: string;
  automerge?: boolean;
  source?: string;
  custom_properties?: { key: string; value: string }[];
  identities?: { key: string; value: string }[];
  // appointments
  start_date?: string;
  // forms
  url?: string;
  actor_type?: ActorType;
  workflow_guid?: string;
  import_guid?: string;
}

export interface PhoneCallActivity {
  call_sid?: string;
  to_number?: string;
  from_number?: string;
}

/**
 * properties coming from clients custom integration event data
 */
export interface CustomIntegrationEventPayload {
  notes?: string;
  context?: string;
}

export interface UserCustomActivityPayload {
  type: string; //
  name: string; // optional
  note: string; // optional
  completed_at: string;
  context?: string;
}

export enum UserCustomActivityContext {
  Profile = 'profile',
  Workflow = 'workflow',
  Todo = 'todo',
  System = 'system'
}

export interface UserCustomActivity
  extends ActivityBase,
    UserCustomActivityPayload {
  context: UserCustomActivityContext;
  created_by: ApiPerson;
  action_description: string;
  todo_guid?: string;
  taxonomy: {
    taxonomy: string;
    name: string;
    guid: string;
  };
  workflow?: {
    name: string;
    guid: string;
  };
  icon: string;
  title: string;
}

export interface UserActivityNotification {
  _id: string;
  guid: string;
  type: string;
}

export interface UserActivityApplication {
  _id: string;
  name: string;
  guid: string;
}

export interface UserActivityInfoRequest {
  _id: string;
  name: string;
  type: string;
  guid: string;
}

export enum UserActivityType {
  Account = 'account',
  Application = 'application',
  Email = 'email',
  Event = 'event',
  InfoRequest = 'info-request',
  Page = 'page',
  Sms = 'sms',
  Survey = 'survey',
  Form = 'form',
  Custom = 'externalActivityCompleted'
}

export enum TraitType {
  String = 'string',
  Date = 'date',
  Boolean = 'boolean',
  Number = 'number'
}

export interface Trait {
  key: string;
  value: string | null;
  type: TraitType;
}

export interface ApiTraits {
  [key: string]: Trait;
}

export interface UserTrait extends Trait {
  id: string;
}

export enum ApplicationSlugs {
  Major = 'user-applications-major',
  Term = 'user-applications-term',
  Campus = 'user-applications-campus',
  Degree = 'user-applications-degree',
  School = 'user-applications-school',
  StudentType = 'user-applications-student-type'
}

export interface RelationshipTarget extends Author {
  email?: string;
  addresses: AddressApi.Address[];
  phone: PhoneApi.Phone[];
}

export interface UserRelationship {
  id: string;
  type: 'custom' | string;
  relation: Pick<TaxonomiesApi.Term, 'guid' | 'name' | 'value'>;
  target: RelationshipTarget;
  is_targeted: boolean;
  meta: {
    probability: number;
    [key: string]: any;
  };
  updated_at: string;
  updated_by: Author;
  created_at: string;
  created_by: Author;
}

export interface UserRelationshipUpdate {
  type: 'custom' | string;
  relation: string;
  target: string;
  is_targeted: boolean;
}

export interface UserPrediction {
  model_guid: string;
  external_resource_id: string;
  histogram: UserPredictionHistogram;
  model_name: string;
  model_description?: string;
  score: number;
}

export interface UserPredictionHistogram {
  properties: {
    name: string;
  };
  result?: PredictionHistogramResult[];
  dimensions?: PredictionHistogramDimensions[];
}

export interface PredictionHistogramResult {
  count: number;
  score: number;
}

export interface PredictionHistogramDimensions {
  name: string;
  key: string;
  type: string;
}

export type UserForChip = Pick<
  UserProfile,
  'avatar' | 'id' | 'first_name' | 'last_name' | 'email'
>;

export interface UsersSearchFilters {
  organizations?: { in?: string[]; notIn?: string[] };
}

export enum UserIdentityTypes {
  SchoolId = 'SCHOOL_ID',
  SearchId = 'SEARCH_ID',
  SparkId = 'SPARK_ID',
  Email = 'EMAIL',
  SalesforceId = 'SALESFORCE_ID',
  EthosId = 'ETHOS_ID',
  ActId = 'ACT_ID',
  CasId = 'CAS_ID',
  BusinessCasId = 'BUSINESS_CAS_ID',
  GraduateCasId = 'GRADUATE_CAS_ID',
  EngineeringCasId = 'ENGINEERING_CAS_ID',
  DentalCasId = 'DENTAL_CAS_ID',
  PharmCasId = 'PHARM_CAS_ID',
  NursingCasId = 'NURSING_CAS_ID',
  PostdocAppSupportServiceCasId = 'PASS_CAS_ID',
  CollegeBoardId = 'COLLEGE_BOARD_ID',
  EncouraId = 'ENCOURA_ID',
  HistoricId = 'HISTORIC_ID',
  SchoolEmail = 'SCHOOL_EMAIL',
  CampusNexusId = 'CAMPUS_NEXUS_ID',
  CommonAppId = 'COMMONAPP_ID',
  CoalitionId = 'COALITION_ID',
  ParchmentId = 'PARCHMENT_ID',
  ScoirId = 'SCOIR_ID',
  CfncId = 'CFNC_ID',
  RcnId = 'RCN_ID',
  CommonAppTransferId = 'COMMONAPP_TRANSFER_ID',
  NicheId = 'NICHE_ID',
  GuildId = 'GUILD_ID',
  PeopleSoftId = 'PEOPLESOFT_ID',
  EabId = 'EAB_ID',
  HscmId = 'HSCM_ID',
  AdvansysId = 'ADVANSYS_ID',
  ZeeMeeId = 'ZEE_MEE_ID',
  OktaId = 'OKTA_ID',
  CaptureId = 'CAPTURE_ID',
  RnlId = 'RNL_ID',
  CommonBlackCollegeAppId = 'COMMON_BLACK_COLLEGE_APP_ID',
  // Custom one, FPO, not available on API
  ElementId = 'ELEMENT_ID',
  UserEmailAddress = 'USER_EMAIL_ADDRESS'
}

export function getUserEmails(user: User) {
  const emails: { type: 'primary' | 'secondary' | 'school'; email: string }[] =
    [{ type: 'primary', email: user.email }];

  try {
    if (user.identities) {
      for (const identity of user.identities) {
        switch (identity.type) {
          case UserIdentityTypes.Email: {
            emails.push({ type: 'secondary', email: identity.value });
            break;
          }
          case UserIdentityTypes.SchoolEmail: {
            emails.push({ type: 'school', email: identity.value });
            break;
          }
        }
      }
    }
  } catch (err) {
    console.error(err);
  }

  return emails.filter(entry => entry.email);
}

export enum IdentitySlugs {
  Email = 'user-identities-email',
  SchoolEmail = 'user-identities-school-email',
  SchoolId = 'user-identities-schoolid',
  CasId = 'user-identities-casid',
  BusinessCasId = 'user-identities-businesscasid',
  GraduateCasId = 'user-identities-gradcasid',
  EngineeringCasId = 'user-identities-engineeringcasid',
  DentalCasId = 'user-identities-aadsasid',
  PharmCasId = 'user-identities-pharmcasid',
  NursingCasId = 'user-identities-nursingcasid',
  PostdocAppSupportServiceCasId = 'user-identities-passid',
  EthosId = 'user-identities-ethosid',
  ParchmentId = 'user-identities-parchmentid',
  CollegeBoardId = 'user-identities-collegeboardid',
  CampusNexusId = 'user-identities-campusnexusid',
  ScoirId = 'user-identities-scoirid',
  SearchId = 'user-identities-searchid',
  CommonAppId = 'user-identities-commonappid',
  CoalitionId = 'user-identities-coalitionid',
  CommonAppTransferId = 'user-identities-commonapptransferid',
  CfncId = 'user-identities-cfncid',
  RcnId = 'user-identities-rcnid',
  NicheId = 'user-identities-nicheid',
  GuildId = 'user-identities-guildid',
  PeopleSoftId = 'user-identities-peoplesoftid',
  EabId = 'user-identities-eabid',
  HscmId = 'user-identities-hscmid',
  ZeeMeeId = 'user-identities-zeemeeid',
  AdvansysId = 'user-identities-advansysid',
  SparkId = 'user-identities-sparkid',
  OktaId = 'user-identities-oktaid',
  CaptureId = 'user-identities-captureid',
  RnlId = 'user-identities-rnlid',
  CommonBlackCollegeAppId = 'user-identities-commonblackcollegeappid'
}

export const IDENTITY_SLUGS = Object.values(IdentitySlugs);
export const IDENTITY_TYPES = Object.values(UserIdentityTypes);

export const IDENTITY_SLUG_TO_TYPE: Record<string, UserIdentityTypes> = {
  [IdentitySlugs.Email]: UserIdentityTypes.Email,
  [IdentitySlugs.SchoolEmail]: UserIdentityTypes.SchoolEmail,
  [IdentitySlugs.SchoolId]: UserIdentityTypes.SchoolId,
  [IdentitySlugs.CasId]: UserIdentityTypes.CasId,
  [IdentitySlugs.EthosId]: UserIdentityTypes.EthosId,
  [IdentitySlugs.ParchmentId]: UserIdentityTypes.ParchmentId,
  [IdentitySlugs.CollegeBoardId]: UserIdentityTypes.CollegeBoardId,
  [IdentitySlugs.CampusNexusId]: UserIdentityTypes.CampusNexusId,
  [IdentitySlugs.ScoirId]: UserIdentityTypes.ScoirId,
  [IdentitySlugs.SearchId]: UserIdentityTypes.SearchId,
  [IdentitySlugs.CommonAppId]: UserIdentityTypes.CommonAppId,
  [IdentitySlugs.CoalitionId]: UserIdentityTypes.CoalitionId,
  [IdentitySlugs.CommonAppTransferId]: UserIdentityTypes.CommonAppTransferId,
  [IdentitySlugs.CfncId]: UserIdentityTypes.CfncId,
  [IdentitySlugs.RcnId]: UserIdentityTypes.RcnId,
  [IdentitySlugs.NicheId]: UserIdentityTypes.NicheId,
  [IdentitySlugs.GuildId]: UserIdentityTypes.GuildId,
  [IdentitySlugs.PeopleSoftId]: UserIdentityTypes.PeopleSoftId,
  [IdentitySlugs.AdvansysId]: UserIdentityTypes.AdvansysId,
  [IdentitySlugs.EabId]: UserIdentityTypes.EabId,
  [IdentitySlugs.HscmId]: UserIdentityTypes.HscmId,
  [IdentitySlugs.ZeeMeeId]: UserIdentityTypes.ZeeMeeId,
  [IdentitySlugs.SparkId]: UserIdentityTypes.SparkId,
  [IdentitySlugs.OktaId]: UserIdentityTypes.OktaId,
  [IdentitySlugs.CaptureId]: UserIdentityTypes.CaptureId,
  [IdentitySlugs.RnlId]: UserIdentityTypes.RnlId,
  [IdentitySlugs.CommonBlackCollegeAppId]:
    UserIdentityTypes.CommonBlackCollegeAppId
};

export const IDENTITY_SLUG_TO_LABEL: Record<string, string> = {
  [IdentitySlugs.Email]: 'Secondary Email',
  [IdentitySlugs.SchoolEmail]: 'School Email',
  [IdentitySlugs.SchoolId]: 'School Id',
  [IdentitySlugs.CasId]: 'CAS Id',
  [IdentitySlugs.BusinessCasId]: 'Business CAS Id',
  [IdentitySlugs.GraduateCasId]: 'Graduate CAS Id',
  [IdentitySlugs.EngineeringCasId]: 'Engineering CAS Id',
  [IdentitySlugs.DentalCasId]: 'Dental CAS Id',
  [IdentitySlugs.PharmCasId]: 'Pharm CAS Id',
  [IdentitySlugs.NursingCasId]: 'Nursing CAS Id',
  [IdentitySlugs.PostdocAppSupportServiceCasId]:
    'Postdoc App Support Service CAS Id',
  [IdentitySlugs.EthosId]: 'Ethos Id',
  [IdentitySlugs.ParchmentId]: 'Parchment Id',
  [IdentitySlugs.CollegeBoardId]: 'College Board Id',
  [IdentitySlugs.CampusNexusId]: 'Campus Nexus Id',
  [IdentitySlugs.ScoirId]: 'Scoir Id',
  [IdentitySlugs.SearchId]: 'Search Id',
  [IdentitySlugs.CommonAppId]: 'Common App Id',
  [IdentitySlugs.CoalitionId]: 'Coalition Id',
  [IdentitySlugs.CommonAppTransferId]: 'Common App Transfer Id',
  [IdentitySlugs.CfncId]: 'CFNC Id',
  [IdentitySlugs.RcnId]: 'RCN',
  [IdentitySlugs.NicheId]: 'Niche Id',
  [IdentitySlugs.GuildId]: 'Guild Id',
  [IdentitySlugs.PeopleSoftId]: 'PeopleSoft Id',
  [IdentitySlugs.EabId]: 'EAB Id',
  [IdentitySlugs.HscmId]: 'HSCM Id',
  [IdentitySlugs.ZeeMeeId]: 'Zee Mee Id',
  [IdentitySlugs.SparkId]: 'Spark Id',
  [IdentitySlugs.OktaId]: 'Okta Id',
  [IdentitySlugs.CaptureId]: 'Capture Id',
  [IdentitySlugs.RnlId]: 'RNL Id',
  [IdentitySlugs.CommonBlackCollegeAppId]: 'Common Black College App Id'
};

export const isIdentitySlug = (slug: string) =>
  IDENTITY_SLUGS.includes(slug as IdentitySlugs);

export function getIdentityLabelFromSlug(slug: string) {
  return IDENTITY_SLUG_TO_LABEL[slug];
}

export interface ApiKey {
  item_id: string;
  key_id: string;
  name: string;
  created_at: string;
}

export interface ApiKeyWithSecret extends ApiKey {
  key_secret: string;
}

export type ApiKeyDto = Pick<ApiKey, 'name'>;

export interface MilestonesFlags {
  email_unsubscribed?: boolean;
  sms_unsubscribed?: boolean;
  email_hard_bounced?: boolean;
  sms_hard_bounced?: boolean;
}

// network
export interface UserNetwork {
  id: string;
  role: string;
  target: UserNetworkTarget;
  created_by: string;
  created_at: string;
}

export interface UserNetworkTarget extends Author {
  email?: string;
  addresses: AddressApi.Address[];
  phone: PhoneApi.Phone[];
  type: UserType;
  name?: string;
  allow_direct_network_conn_messages?: boolean;
  office_building?: string;
  office_room?: string;
  appointments_slug?: string;
}

export interface UserNetworkCreateDto {
  role_id: string;
  user_id: string;
}
