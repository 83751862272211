import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiFile,
  ApplicationsApi,
  Decision451Api,
  FormsApi,
  MessagesApi,
  PaymentApi
} from '@element451-libs/models451';
import { mapApolloResponse } from '@element451-libs/utils451/rxjs';
import { Apollo } from 'apollo-angular';
import { forEach } from 'lodash';
import {
  API451_CLIENT,
  API451_URL_FACTORY,
  ApiClient,
  UrlFactory
} from '../api-client';
import {
  Api451Done,
  ElmResponse,
  ElmResponse2,
  toEncodedItem,
  toItem,
  toPlainItem,
  urlEncodedHeaders
} from '../shared';
import { ApplicationsApiModule } from './applications-api.module';
import { getUsersCountByApplication } from './queries/get-users-by-application.query';

// shorthand
type R<T> = ElmResponse<T>;
type R2<T> = ElmResponse2<T>;

interface DepositContext {
  registrationId: string;
  applicationGuid: string;
  termGuid: string;
  majorGuid: string;
}

interface GetApplicationsParams {
  offset?: number;
  limit?: number;
  active?: number;
  guids?: string[];
}

function registrationIdFactory(registrationId: string) {
  return new HttpParams().set('registration_id', registrationId);
}

export function depositContextParamsFactory(
  context: DepositContext
): HttpParams {
  return new HttpParams()
    .set('registration_id', context.registrationId)
    .set('context[registration_id]', context.registrationId)
    .set('context[app_guid]', context.applicationGuid)
    .set('context[term_guid]', context.termGuid)
    .set('context[major_guid]', context.majorGuid);
}

@Injectable({
  providedIn: ApplicationsApiModule
})
export class ApplicationsApiService {
  constructor(
    private http: HttpClient,
    @Inject(API451_CLIENT) private apiClient: ApiClient,
    @Inject(API451_URL_FACTORY) private url: UrlFactory,
    private apollo: Apollo
  ) {}

  get(guid: string) {
    return this.http.get<R<ApplicationsApi.Application>>(
      this.url(`sites/app451/${guid}`)
    );
  }

  getAll(params?: GetApplicationsParams) {
    const fromObject = {};

    fromObject['embed[list]'] = 1;

    if (params) {
      if (params.active !== undefined) {
        fromObject['active'] = params.active;
      }

      if (params.guids) {
        fromObject['guids'] = params.guids;
      }

      if (params.offset !== undefined) {
        fromObject['offset'] = params.offset;
      }

      if (params.limit !== undefined) {
        fromObject['limit'] = params.limit;
      }
    }

    const httpParams = new HttpParams({ fromObject });

    return this.http.get<R<ApplicationsApi.Application[]>>(
      this.url(`applications`),
      {
        params: httpParams
      }
    );
  }

  getOne(guid: string) {
    return this.http.get<R<ApplicationsApi.Application>>(
      this.url(`applications/private/${guid}`)
    );
  }

  delete(guid: string) {
    return this.http.delete<R<Api451Done>>(this.url(`applications/${guid}`));
  }

  edit(guid: string, data: Partial<ApplicationsApi.ApplicationDto>) {
    return this.http.put<R<ApplicationsApi.Application>>(
      this.url(`applications/${guid}`),
      toPlainItem(data)
    );
  }

  usersCountByApplicationId(applicationGuid: string) {
    return this.apollo
      .query({
        query: getUsersCountByApplication,
        fetchPolicy: 'cache-first',
        variables: {
          input: {
            applicationGuid
          }
        }
      })
      .pipe(mapApolloResponse(result => result?.countUsersByApplication));
  }

  create(application: Partial<ApplicationsApi.ApplicationDto>) {
    return this.http.post<R<ApplicationsApi.Application>>(
      this.url('applications'),
      toPlainItem(application)
    );
  }

  duplicate(guid: string) {
    return this.http.post<R<ApplicationsApi.Application>>(
      this.url(`applications/${guid}/clone`),
      null
    );
  }

  // steps
  createStep(applicationId: string, step: Partial<ApplicationsApi.Section>) {
    return this.http.post<R<ApplicationsApi.Section>>(
      this.url(`applications/${applicationId}/steps`),
      toPlainItem(step)
    );
  }

  editStep(applicationId: string, step: Partial<ApplicationsApi.Section>) {
    const { _id: stepId, ...payload } = step;

    return this.http.put<R<ApplicationsApi.Section>>(
      this.url(`applications/${applicationId}/steps/${stepId}`),
      toPlainItem(payload)
    );
  }

  deleteStep(applicationId: string, stepId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`applications/${applicationId}/steps/${stepId}`)
    );
  }

  // sections
  createSection(
    applicationId: string,
    stepId: string,
    section: Partial<ApplicationsApi.Section>
  ) {
    return this.http.post<R<ApplicationsApi.Section>>(
      this.url(`applications/${applicationId}/steps/${stepId}/sections`),
      toPlainItem(section)
    );
  }

  deleteSection(applicationId: string, stepId: string, sectionId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(
        `applications/${applicationId}/steps/${stepId}/sections/${sectionId}`
      )
    );
  }

  editSection(
    applicationId: string,
    stepId: string,
    section: Partial<ApplicationsApi.Section>
  ) {
    const { _id: sectionId, ...payload } = section;

    return this.http.put<R<ApplicationsApi.Section>>(
      this.url(
        `applications/${applicationId}/steps/${stepId}/sections/${sectionId}`
      ),
      toPlainItem(payload)
    );
  }

  // fields
  editSectionFields(
    applicationId: string,
    stepId: string,
    sectionId: string,
    form: FormsApi.FormData
  ) {
    return this.http.put<R<FormsApi.FormData>>(
      this.url(
        `applications/${applicationId}/steps/${stepId}/sections/${sectionId}/field`
      ),
      toPlainItem(form)
    );
  }

  editFields(guid: string, form: FormsApi.FormData) {
    return this.http.put<R<FormsApi.FormData>>(
      this.url(`fields/${guid}`),
      toEncodedItem(form),
      urlEncodedHeaders
    );
  }

  editDashboardGreeting(guid: string, greeting: ApplicationsApi.GreetingHero) {
    const { status, ...payload } = greeting;

    return this.http.put<R<ApplicationsApi.GreetingHero>>(
      this.url(`applications/${guid}/dashboard/hero/${status}`),
      toItem(payload)
    );
  }

  // dashboard cards
  addCard(guid: string, card: ApplicationsApi.InfoBlock) {
    return this.http.post<R<ApplicationsApi.InfoBlock>>(
      this.url(`applications/${guid}/dashboard/cards/`),
      toItem(card)
    );
  }

  editCard(guid: string, card: ApplicationsApi.InfoBlock) {
    const { _id, ...payload } = card;

    return this.http.put<R<ApplicationsApi.InfoBlock>>(
      this.url(`applications/${guid}/dashboard/cards/${_id}`),
      toItem(payload)
    );
  }

  deleteCard(guid: string, cardId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`applications/${guid}/dashboard/cards/${cardId}`)
    );
  }

  editInfoRequestDetails(
    guid: string,
    infoRequest: ApplicationsApi.InfoRequestDetails
  ) {
    return this.http.put<R<ApplicationsApi.InfoRequestDetails>>(
      this.url(`applications/${guid}/infoRequest`),
      toPlainItem(infoRequest)
    );
  }

  deleteInfoRequest(applicationId: string, infoRequestId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(
        `applications/${applicationId}/infoRequest/${infoRequestId}/remove`
      )
    );
  }

  editInfoRequest(
    applicationId: string,
    infoRequest: Partial<ApplicationsApi.InfoRequestItem>
  ) {
    const { info_request, ...payload } = infoRequest;

    return this.http.put<R<ApplicationsApi.Application>>(
      this.url(`applications/${applicationId}/infoRequest/${info_request}`),
      toPlainItem(payload)
    );
  }

  addInfoRequest(
    applicationId: string,
    infoRequest: Partial<ApplicationsApi.InfoRequestItem>
  ) {
    const payload = {
      application: applicationId,
      ...infoRequest
    };

    return this.http.put<R<ApplicationsApi.InfoRequestItem>>(
      this.url(`applications/recommendations/info/request`),
      toItem(payload)
    );
  }

  getPublicForm(guid: string) {
    return this.http.get<R<ApplicationsApi.Form>>(this.url(`fields/${guid}`));
  }

  getUserApplicationsPublic(userId: string) {
    return this.http.get<R2<ApplicationsApi.UserApplication[]>>(
      this.url(`users/${userId}/applications`)
    );
  }

  getUserApplications(userId: string) {
    return this.http.get<R2<ApplicationsApi.UserApplication[]>>(
      this.url(`users/${userId}/applications/admin`)
    );
  }

  getUserApplicationData(applicationId: string) {
    return this.http.get<R2<ApplicationsApi.UserApplicationData>>(
      this.url(`users/applications/${applicationId}/user_data`)
    );
  }

  getUserApplicationDocuments(registration_id: string) {
    return this.http.get<R2<ApplicationsApi.UserDocument[]>>(
      this.url(`users/applications/${registration_id}/user_files`)
    );
  }

  getDashboard(registrationId: string) {
    return this.http.get<R2<ApplicationsApi.Dashboard>>(
      this.url(`users/applications/${registrationId}/dashboard`)
    );
  }

  getStep(registrationId: string, sectionId: string) {
    return this.http.get<R2<ApplicationsApi.Step>>(
      this.url(`users/applications/${registrationId}/sections/${sectionId}`)
    );
  }

  getInfoRequest(registrationId: string) {
    return this.http.get<R<ApplicationsApi.InformationRequest>>(
      this.url(`users/applications/${registrationId}/info_request`)
    );
  }

  getPaymentInfo(userId: string, registrationId: string) {
    return this.http.get<R2<any>>(
      this.url(`users/${userId}/applications/${registrationId}/payment-info`)
    );
  }

  createRecommendationRequest(
    registrationId: string,
    data: ApplicationsApi.CreateRequestData
  ) {
    const params = new HttpParams({
      fromObject: { registration_id: registrationId }
    });

    return this.http.post<
      R<{ info_requests: ApplicationsApi.UserInfoRequest[] }>
    >(this.url(`applications/recommendations/request`), toItem(data), {
      params
    });
  }

  getUserInfoRequests(registrationId: string) {
    return this.http.get<R<ApplicationsApi.UserInfoRequest[]>>(
      this.url(`users/applications/${registrationId}/info_request/user_data`)
    );
  }

  resendUserInfoRequest(registrationId: string, infoRequestId: string) {
    const data = { info_request: infoRequestId };

    return this.http.post<R<ApplicationsApi.UserInfoRequest>>(
      this.url(
        `users/applications/${registrationId}/info_request/${infoRequestId}/resend`
      ),
      toItem(data)
    );
  }

  deleteUserInfoRequest(infoRequestId: string) {
    return this.http.delete<R<Api451Done>>(
      this.url(`applications/recommendations/remove/${infoRequestId}`)
    );
  }

  addRecommendation(data: ApplicationsApi.AddRequestData) {
    return this.http.post<
      R<{ documents: ApplicationsApi.RecommedationDocuments }>
    >(this.url(`applications/recommendations/add`), toItem(data));
  }

  removeRecommendationFile(fieldName: string, fileGuid?: string) {
    return this.http.delete<R<any>>(
      this.url(
        `applications/recommendations/file/${fieldName}${fileGuid ? `/${fileGuid}` : ''}`
      )
    );
  }

  submitForm(
    applicationGuid: string,
    formGuid: string,
    registrationId: string,
    data: ApplicationsApi.FormSubmitItem
  ) {
    let params = registrationIdFactory(registrationId);
    params = params.set('part', '1');

    const formData = new FormData();
    formData.set('item', JSON.stringify(data));
    forEach(data.files, file => formData.set(file.key, file.file));

    return this.http.post<R<ApplicationsApi.FormSubmitResponse>>(
      this.url(`applications/${applicationGuid}/forms2/${formGuid}`),
      formData,
      { params }
    );
  }

  submitApplication(
    applicationGuid: string,
    registrationId: string,
    values: { [name: string]: any }
  ) {
    const params = registrationIdFactory(registrationId);
    const data = { values };

    return this.http.post<R<Api451Done>>(
      this.url(`applications/${applicationGuid}/submit`),
      toItem(data),
      {
        params
      }
    );
  }

  declineOffer(registrationId: string) {
    const data = {
      action: 'decline'
    };
    return this.http.post<R<Api451Done>>(
      this.url(`users/applications/decision/${registrationId}/confirmation`),
      toItem(data)
    );
  }

  removeFile(
    applicationGuid: string,
    formGuid: string,
    fileGuid: string,
    registrationId: string,
    itemId?: string
  ) {
    let params = registrationIdFactory(registrationId);

    if (itemId) {
      params = params.set('item_id', itemId);
    }

    return this.http.delete<R<ApplicationsApi.FileRemoveResponse>>(
      this.url(
        `applications/${applicationGuid}/forms/${formGuid}/file/${fileGuid}/delete`
      ),
      { params }
    );
  }

  deleteRepeaterItem(
    applicationGuid: string,
    registrationId: string,
    formGuid: string,
    slug: string,
    weight: number
  ) {
    let params = registrationIdFactory(registrationId);
    params = params.set('weight[]', `${weight}`);

    return this.http.delete<R<ApplicationsApi.DeleteRepeaterItemResponse>>(
      // eslint-disable-next-line max-len
      this.url(
        `applications/${applicationGuid}/forms/${formGuid}/repeaters/${slug}`
      ),
      { params }
    );
  }

  generatePreview(applicationGuid: string, registrationId: string) {
    let params = registrationIdFactory(registrationId);
    params = params.set('output', 'pdf').set('delivery', 'notification');

    const headers = new HttpHeaders({
      feature: this.apiClient.featureToken
    });

    return this.http.get<R<null>>(
      this.url(`applications/${applicationGuid}/preview`),
      {
        headers,
        params
      }
    );
  }

  getSnapApp(applicationGuid: string, registrationId: string) {
    const params = registrationIdFactory(registrationId);

    return this.http.get<R<ApplicationsApi.SnapAppResponse>>(
      this.url(`applications/${applicationGuid}/snap/sections`),
      { params }
    );
  }

  sendCreditCardPayment(
    applicationGuid: string,
    registrationId: string,
    creditCardInfo: PaymentApi.CreditCardInformation,
    conditionId?: string | null,
    couponCode?: string | null
  ) {
    let params = registrationIdFactory(registrationId);

    if (conditionId) {
      params = params.set('payment_condition', conditionId);
    }

    if (couponCode) {
      params = params.set('coupon_code', couponCode);
    }

    const creditCard = { fields: creditCardInfo };

    return this.http.post<R<Api451Done>>(
      this.url(`applications/${applicationGuid}/payment/cc`),
      toItem(creditCard),
      { params }
    );
  }

  sendDepositCreditCardPayment(
    depositId: string,
    context: DepositContext,
    creditCardInfo: PaymentApi.CreditCardInformation,
    conditionId: string | null,
    couponCode: string | null
  ) {
    let params = depositContextParamsFactory(context);

    if (conditionId) {
      params = params.set('payment_condition', conditionId);
    }

    if (couponCode) {
      params = params.set('coupon_code', couponCode);
    }

    const creditCard = { fields: creditCardInfo };

    return this.http.post<R<Api451Done>>(
      this.url(`users/deposits/${depositId}/credit_card`),
      toItem(creditCard),
      { params }
    );
  }

  sendCheckPayment(
    applicationGuid: string,
    registrationId: string,
    checkNumber: string,
    conditionId?: string | null,
    couponCode?: string | null
  ) {
    let params = registrationIdFactory(registrationId);

    if (conditionId) {
      params = params.set('payment_condition', conditionId);
    }

    if (couponCode) {
      params = params.set('coupon_code', couponCode);
    }

    const check = {
      fields: [{ name: 'check_number', value: checkNumber }]
    };

    return this.http.post<R<Api451Done>>(
      this.url(`applications/${applicationGuid}/payment/check`),
      toItem(check),
      {
        params
      }
    );
  }

  savePaymentReceipt(
    applicationGuid: string,
    registrationId: string,
    { integration_id, ...receipt }: PaymentApi.PaymentReceipt
  ) {
    let params = registrationIdFactory(registrationId);
    params = params.set('integration_id', integration_id);

    return this.http.post<R<Api451Done>>(
      this.url(`applications/${applicationGuid}/payment/status`),
      toItem(receipt),
      { params }
    );
  }

  getFieldSlugNameMappings(applicationGuid: string) {
    return this.http.get<R<ApplicationsApi.FieldSlugNameMappingsResponse>>(
      this.url(`applications/${applicationGuid}/form/mapping`)
    );
  }

  getChecklist(registrationId: string) {
    return this.http.get<R2<ApplicationsApi.ChecklistItem[]>>(
      this.url(`users/applications/decision/${registrationId}/checklist`)
    );
  }

  getDocuments(userId: string, registrationId: string) {
    return this.http.get<R2<ApplicationsApi.DocumentFile[]>>(
      this.url(`users/${userId}/applications/${registrationId}/documents/admin`)
    );
  }

  getLetters(registrationId: string, userId?: string) {
    let params = new HttpParams();
    // used in conjunction with service header
    if (userId) {
      params = params.set('user_id', userId);
    }

    return this.http.get<R2<string[]>>(
      this.url(`users/applications/decision/${registrationId}/letters`),
      {
        params
      }
    );
  }

  getPersonalizedLetterTokens(registrationId: string, userId?: string) {
    let params = new HttpParams();
    // used in conjunction with service header
    if (userId) {
      params = params.set('user_id', userId);
    }

    return this.http.get<R2<Decision451Api.ReviewerToken[]>>(
      this.url(`users/applications/decision/${registrationId}/letters/tokens`),
      { params }
    );
  }

  generateDocument(
    applicationId: string,
    registrationId: string,
    userId: string
  ) {
    const params = registrationIdFactory(registrationId);

    return this.http.get<R<ApiFile>>(
      this.url(`applications/${applicationId}/user/${userId}/download`),
      { params }
    );
  }

  changeStatus(
    applicationId: string,
    registrationId: string,
    userId: string,
    status: string
  ) {
    const params = registrationIdFactory(registrationId);

    return this.http.post<R<Api451Done>>(
      this.url(`applications/${applicationId}/changestatus/${userId}`),
      toItem({ status }),
      { params }
    );
  }

  unsubscribeUserFromApplication(
    applicationId: string,
    registrationId: string,
    userId: string
  ) {
    const params = registrationIdFactory(registrationId);

    return this.http.delete<R<Api451Done>>(
      this.url(`applications/${applicationId}/unsubscribe/${userId}`),
      { params }
    );
  }

  getMessages(appGuid: string) {
    return this.http.get<R<MessagesApi.Message[]>>(
      this.url(`applications/settings/autoresponders/${appGuid}`)
    );
  }

  updateMessage(appGuid: string, message: MessagesApi.Message) {
    const { key, ...props } = message;

    const payload = {
      ...props,
      delay: {
        offset: '+100y'
      }
    };

    const params = [`key=${key}`, toEncodedItem(payload)].join('&');

    return this.http.put<R<MessagesApi.Message[]>>(
      this.url(`applications/settings/autoresponders/${appGuid}`),
      params,
      urlEncodedHeaders
    );
  }

  sendMessagePreview(appGuid: string, config: MessagesApi.Preview) {
    return this.http.post<R<MessagesApi.Message[]>>(
      this.url(`applications/settings/autoresponders/${appGuid}/try`),
      toItem(config)
    );
  }

  getApplicationDocumentsZip(userId: string, registrationId: string) {
    return this.http.get<{ last_export: { file_url: string } }>(
      this.url(`users/${userId}/applications/${registrationId}/exports`)
    );
  }
}
