import { gql } from 'apollo-angular';
export {
  Decision451_SearchDecisionsQuery,
  Decision451_SearchDecisionsQueryVariables
} from '@element451-libs/graphql-comet/client';

export const searchDecisionsQuery = gql`
  query Decision451_SearchDecisions(
    $query: String
    $filters: JSON
    $page: Int
    $limit: Int
    $sort: String
  ) {
    count: getDecisionCount(input: { query: $query, filters: $filters })
    list: searchDecisions(
      input: {
        query: $query
        filters: $filters
        limit: $limit
        page: $page
        sort: $sort
      }
    ) {
      _id
      user {
        _id
        first_name
        last_name
        middle_name
        preferred_name
        email
        date_of_birth
        identities
        active
      }
      assigned_to
      last_reviewer
      application_guid
      stage_id
      status_guid
      registration_id
      checklist {
        completed
        required
        required_completed
        total
      }
      score
      favorite
      board_version
      created_at
      tags {
        items
        _version
      }
      tests
      fraud: fraud {
        fraud_category
        reasoning
        resolved_as
        resolved_by
        fraud_evaluation
      }
    }
  }
`;
