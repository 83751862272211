import { Pipe, PipeTransform } from '@angular/core';
import { unescape } from 'lodash';

export function stripHtml(value: string): string {
  if (!value) return '';
  const formatted = value
    .replace(/<\/?[\w\s="/.':;#-/?]+>/gi, '')
    .replace(/&nbsp;/gm, ' ');
  return unescape(formatted);
}

@Pipe({ name: 'elmStripHtml' })
export class StripHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return stripHtml(value);
  }
}
