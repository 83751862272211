import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CustomPreloadingStrategy, PreloadSelectedModulesList } from './core';
import { AuthGuard } from './guards';
import { SiteResolver } from './resolvers';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    resolve: { SiteResolver }
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    resolve: { SiteResolver },
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () =>
      import('./edit-profile/edit-profile.module').then(
        m => m.EditProfileModule
      ),
    resolve: { SiteResolver }
  },
  {
    path: 'recommend',
    loadChildren: () =>
      import('./recommend/recommend.module').then(m => m.RecommendModule),
    resolve: { SiteResolver }
  },
  /**
   * When we're doing SSO authentication, and it fails on API side,
   * they can't figure out if that's internal or external user, so they'll always redirect
   * to v2/login/:token (as that's default for dashboard) so we're doing redirect here to be
   * able gracefully handle that case.
   */
  {
    path: 'v2/login/:token',
    redirectTo: '/login/:token',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    resolve: { SiteResolver }
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./logout/logout.module').then(m => m.LogoutModule)
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      ),
    resolve: { SiteResolver }
  },
  {
    path: 'page-not-found',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        m => m.PageNotFoundModule
      )
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: CustomPreloadingStrategy
    })
  ],
  exports: [RouterModule],
  providers: [
    SiteResolver,
    AuthGuard,
    {
      provide: CustomPreloadingStrategy,
      useClass: PreloadSelectedModulesList
    }
  ]
})
export class AppRoutingModule {}
