import { TaxonomiesApi } from '../taxonomies';
import { UsersApi } from '../users';

export interface DocumentsTaxonomies {
  folders: {
    parent: string;
  };
  tags: {
    parent: string;
  };
}

export interface ApiDocumentType {
  guid: string;
  icon: string;
  name: string;
  slug: string;
  created_at?: string;
}

export interface DocumentMeta {
  sparkid: string;
  [value: string]: string;
}

export interface DocumentTerm extends TaxonomiesApi.Term {
  created_at?: string;
  updated_at?: string;
  properties?: {
    color: string;
    slug: string;
  };
}

export interface ApiDocumentTransformation {
  url: string;
  preview_url: string;
  /**
   * for documents that have preview url as presigned aws links that expire
   * this property will contain expiration date, after which that link is not valid
   * anymore
   */
  expiration_time?: string;
}

export interface ApiDocumentTransformations {
  document_jpg: ApiDocumentTransformation;
  [key: string]: ApiDocumentTransformation;
}

export interface DocumentUser extends UsersApi.ApiPerson {
  email: string;
}

export interface ApiDocument {
  author: DocumentUser;
  created_at: string;
  file_guid: string;
  folder: DocumentTerm;
  guid: string;
  mime_type: string;
  name: string;
  original_file: string;
  result?: string;
  size: number;
  extension?: string;
  source: string;
  tags?: TaxonomiesApi.Term[];
  type: ApiDocumentType;
  type_guid: string;
  updated_at: string;
  meta?: DocumentMeta;
  import_task?: string;
  url?: string;
  preview_url?: string;
  user?: DocumentUser;
  transformations?: ApiDocumentTransformations;
  alt_name?: string;
  icon?: string;
}

export const MULTI_DOCUMENT_SUPPORTED_EXTENSIONS = [
  '.doc',
  '.docx',
  '.htm',
  '.html',
  '.jpeg',
  '.jpg',
  '.odt',
  '.pdf',
  '.png',
  '.txt'
];

export const DOCUMENT_SUPPORTED_EXTENSIONS = [
  '.pdf',
  '.doc',
  '.docx',
  '.txt',
  '.odt',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.csv',
  '.ppt',
  '.pptx',
  '.svg',
  '.txt',
  '.xls',
  '.xlsx',
  '.html',
  '.bmp',
  '.htm'
];

export const AUDIO_VIDEO_SUPPORTED_EXTENSIONS = [
  '.mp4',
  '.mov',
  '.mwv',
  '.avi',
  '.mp3',
  '.acc',
  '.ogg',
  '.ogm',
  '.wav',
  '.wma',
  '.webm'
];

export const DOCUMENT_EXTENSIONS_TO_DISPLAY = [
  'pdf',
  'png',
  'gif',
  'jpg',
  'jpeg',
  'svg',
  'txt',
  ...AUDIO_VIDEO_SUPPORTED_EXTENSIONS
];

export const UNTYPED_DOCUMENT = {
  guid: 'UNTYPED',
  icon: '',
  name: 'Untyped',
  slug: 'untyped'
};
