import {
  cloneDeep,
  Dictionary,
  forEach,
  isNil,
  isPlainObject,
  keys
} from 'lodash';
import { IField, IFieldWithData, IFormData } from '../../api-data';

export function convertToFieldsWithData(
  data: any,
  form: IFormData,
  options: { allowBlankValues?: boolean } = {}
): IFieldWithData[] {
  if (!isPlainObject(data)) return [];
  const tables = normalizeForm(form);
  return toData(data, tables, null, options);
}

function toData(
  data: any,
  tables: { fieldByName: Dictionary<IField>; fieldBySlug: Dictionary<IField> },
  parentFieldName: string = null,
  options: { allowBlankValues?: boolean } = {}
): IFieldWithData[] {
  return keys(data).reduce((fields, key) => {
    const value = data[key];

    const field =
      tables.fieldByName[key] ||
      tables.fieldBySlug[key] ||
      /**
       * for phone, address, etc. subfields need parent field name to get the full name
       * since API returns something like
       * {
          "user-first-name": "John",
          "user-last-name": "Doe",
          "user-email-address": "john@doe.com",
          "user-phone-cell": {
            "type": "cell",
            "country_alpha_2": "AD",
            "country_code": "376",
            "number": "12312312"
          }
        }
        * And phone field has following configuration
        *
        * {
        *   slug: "user-phone-cell"
        *   name: "user-phone",
        *   subfields: [
          *    {
          *       name: "user-phone-type"
          *    },
          *    {
          *       name: "user-phone-country_alpha_2"
          *    }
        *   ]
        * }
        *
       */
      (parentFieldName && tables.fieldByName[`${parentFieldName}-${key}`]);

    if (field && field.subfields) {
      fields = fields.concat(toData(value, tables, field.name));
    } else if (field && !isNil(value)) {
      fields.push({
        key: field.name,
        type: field.type,
        value
      });
    } else if (field && options.allowBlankValues) {
      fields.push({
        key: field.name,
        type: field.type,
        value: null
      });
    }

    return fields;
  }, []);
}

export function normalizeForm(
  form: IFormData,
  /**
   * Hook to mutate fields in the form tree
   */
  applyTransformation: Visitor = () => {}
) {
  form = cloneDeep(form);
  const fieldByName = {};
  const fieldBySlug = {};

  const visit = (field: IField, parent: IField) => {
    if (!field.name) console.error(field);

    if (applyTransformation) applyTransformation(field, parent);

    if (parent) {
      // we normalize the form subfields
      const index = parent.subfields.indexOf(field);
      (parent.subfields as unknown as string[])[index] =
        field.name || field.slug;
    }

    if (field.name) fieldByName[field.name] = field;
    if (field.slug) fieldBySlug[field.slug] = field;
  };

  // start traversing
  traverseFormFields(form?.fields, null, visit);

  // when traversing is done return our table and mutated form
  return { fieldByName, fieldBySlug, form };
}

export type Visitor = (field: IField, parent: IField) => void;
export const traverseFormFields = (
  fields: IField[],
  parent: IField,
  visit: Visitor
) =>
  forEach(fields, field => {
    visit(field, parent);
    traverseFormFields(field.subfields, field, visit);
  });
