export const combinePermissions = (
  ...permissions: (PERMISSION | string)[]
): string => {
  return permissions.join(' || ');
};

export const splitPermissions = (permissions: string): PERMISSION[] => {
  return permissions.split(' || ') as PERMISSION[];
};

export function evaluateExpression(
  expression: string,
  permissions: { [permission: string]: any }
): boolean {
  if (expression) {
    // Expression Examples:
    // 'permissionSlug1'
    // '!permissionSlug1'
    // 'permissionSlug1 && permissionSlug2'
    // 'permissionSlug1 || permissionSlug2'
    // '!permissionSlug1 && permissionSlug2 || permissionSlug3'

    // Expression Groups:
    // group #1: logical operator NOT (!) - optional
    // group #2: permission slug (word, digit, dash and underscore)
    // group #3: logical operators AND (&&) or OR (||) - optional
    const EXPRESSION_REGEXP = /(!?)([\w\d-_]+)\s*(&{2}|\|{2})?/g;
    const expressionGroups: string[] = [];

    let match,
      resultExpression = '';

    while ((match = EXPRESSION_REGEXP.exec(expression)) !== null) {
      // loop through all three groups
      for (let i = 1; i <= 3; i++) {
        if (match[i]) {
          expressionGroups.push(match[i]);
        }
      }
    }

    expressionGroups.forEach(value => {
      if (value === '!' || value === '&&' || value === '||') {
        resultExpression += value;
      } else {
        resultExpression += !!permissions[value];
      }
    });

    return new Function('return ' + resultExpression)();
  } else {
    return true;
  }
}

export const enum PERMISSION {
  NOT_REQUIRED = '',

  APP = 'app451',
  APP_ADMIN = 'administer-applications',
  APP_WAIVER_ADMIN = 'administer-application-waivers',

  APP_PROFILES_ADMIN = 'administer-application-profiles',
  DELETE_USER_APP = 'delete-user-application',
  DELETE_USER = 'delete-user',
  CHANGE_USER_PASSWORD = 'change-user-password',
  EDIT_USER_STATUS = 'edit-user-status',

  ADMINISTER_ADMINS = 'administer-admins',

  ADMINISTER_USERS = 'administer-users',
  ACCESS_USERS = 'access-users',
  SECURITY_ADMIN = 'administer-security',
  MANAGE_API_KEYS = 'manage-api-keys',
  MANAGE_SSO_SETTINGS = 'manage-sso-settings',

  CAMPAIGNS = 'campaigns451',
  CAMPAIGNS_ADMIN = 'administer-campaigns',
  CAMPAIGNS_COMMUNICATIONS_ADMIN = 'administer-campaigns-communications',
  CAMPAIGNS_TEMPLATES_ADMIN = 'administer-campaigns-templates',
  CAMPAIGNS_SEND_COMMUNICATIONS = 'send-communications',
  CAMPAIGNS_SEND_OWN_COMMUNICATIONS = 'send-own-communications',
  CAMPAIGNS_SEND_TEAM_COMMUNICATIONS = 'send-team-communications',
  CAMPAIGNS_EDIT_OWN_COMMUNICATIONS = 'edit-own-communications',
  CAMPAIGNS_EDIT_TEAM_COMMUNICATIONS = 'edit-team-communications',
  CAMPAIGNS_TAGS_CREATE = 'create-communication-tags',
  CAMPAIGNS_TAGS_DELETE = 'delete-communication-tags',
  CAMPAIGNS_TAGS_EDIT = 'edit-communication-tags',

  CONVERSATIONS = 'conversations451',
  CONVERSATIONS_ADMIN = 'administer-conversations',
  CONVERSATIONS_MANAGE = 'manage-conversations',

  DEV = 'dev451',

  INSIGHTS = 'insights451',

  INSIGHTS_GENERAL_DASHBOARD = 'insights-access-general-dashboard',
  INSIGHTS_TASKS_DASHBOARD = 'insights-access-tasks-dashboard',
  INSIGHTS_CAMPAIGNS_DASHBOARD = 'insights-access-campaigns-dashboard',
  INSIGHTS_EVENTS_DASHBOARD = 'insights-access-events-dashboard',
  INSIGHTS_FUNNEL_DASHBOARD = 'insights-access-funnel-dashboard',
  INSIGHTS_SOURCES_DASHBOARD = 'insights-access-sources-dashboard',
  INSIGHTS_CONVERSATIONS_DASHBOARD = 'insights-access-conversations-dashboard',
  INSIGHTS_WEB_ACTIVITIES_DASHBOARD = 'insights-access-web-activities-dashboard',
  INSIGHTS_MANAGEMENT_DASHBOARD = 'insights-access-management-dashboard',
  INSIGHTS_APPOINTMENTS_DASHBOARD = 'insights-access-appointments-dashboard',
  INSIGHTS_DATA_HYGIENE_DASHBOARD = 'insights-access-data-hygiene-dashboard',
  INSIGHTS_PAYMENTS_DASHBOARD = 'insights-access-payments-dashboard',
  INSIGHTS_APPLICATIONS_DASHBOARD = 'insights-access-applications-dashboard',
  INSIGHTS_DISCOVERY_DASHBOARD = 'insights-access-discovery-dashboard',

  PACKS_EXECUTE = 'execute-packs',

  PEOPLE_VIEW_PROFILES = 'view-profiles',
  PEOPLE_EDIT_PROFILES = 'edit-profiles',
  PEOPLE_PROFILES_ADMIN = 'administer-profiles',

  PROFILE_TEMPLATES_ADMIN = 'administer-profile-templates',
  LABELS_ADMIN = 'administer-labels',

  DECISION = 'decision451',
  DECISION_OVERVIEW = 'access-decisions-overview',
  DECISION_APPLICATIONS = 'administer-decisions-applications',
  DECISION_SETTINGS = 'administer-decisions-settings',
  DECISION_REPORTS = 'access-decisions-reports',
  DECISION_VIEW_FRAUD_STATUS = 'decisions-view-fraud-status',
  DECISION_UPDATE_FRAUD_STATUS = 'decisions-update-fraud-status',

  DEDUPLICATION = 'administer-duplicates',

  DATA = 'data451',
  DATA_BULK_EXPORT = 'create-bulkexport',
  DATA_SOURCES_ADMIN = 'administer-data-sources',
  DATA_SOURCE_CODES_ADMIN = 'administer-source-codes',

  IMPORT_EXPORT_ADMIN = 'administer-import-export',
  IMPORT_EXPORT_ACCESS = 'import_export',

  EVENT = 'event451',
  EVENT_ADMIN = 'administer-events',
  EVENT_ADMIN_ATTENDEES = 'administer-event-attendees',

  MICROSITES_ADMIN = 'administer-sites',

  PAGE = 'page451',

  PROSPECT = 'prospect451',
  FORMS_ADMIN = 'administer-forms',

  WORKFLOWS_ADMIN = 'administer-workflows',
  WORKFLOWS_ACCESS = 'access-workflows',
  WORKFLOWS_EDIT_OWN = 'edit-own-workflows',
  WORKFLOWS_EDIT_TEAM = 'edit-team-workflows',
  WORKFLOWS_FOLDER_ADMIN = 'administer-workflows-folders',

  WEBHOOKS_ADMIN = 'administer-webhooks',
  WEBHOOKS_ACCESS = 'access-webhooks',

  SEGMENTS_ADMIN = 'administer-segments',
  CREATE_SEGMENT = 'create-segment',
  EDIT_OWN_SEGMENTS = 'edit-own-segments',
  EDIT_SEGMENTS = 'edit-segments',
  SEGMENTS_DELETE = 'delete-segments',
  SEGMENTS_TAGS_ADMIN = 'administer-segment-tags',
  SEGMENTS_FOLDERS_ADMIN = 'administer-segment-folders',
  SEGMENTS_APPLY_LABELS = 'apply-segment-labels',

  SETTINGS_PRIVACY_ADMIN = 'administer-settings-privacy',
  SETTINGS_ADMIN = 'administer-settings',

  SITE = 'site451',

  TASKS_ACCESS = 'access-tasks',
  TASKS_ADMIN = 'administer-tasks',
  TASKS_QUEUES_ADMIN = 'administer-tasks-queues',
  TASKS_USER = 'user-tasks',
  CONTACT_TASKS_ACCESS = 'access-contact-tasks',
  CONTACT_TASKS_USER = 'user-contact-tasks',

  DASHBOARDS_ACCESS = 'access-dashboards',
  DASHBOARDS_ADMIN = 'administer-dashboards',

  DOCUMENTS_ADMIN = 'administer-documents',

  EDIT_VISIBILITY_GROUPS = 'edit-visibility-groups',

  SURVEYS_ACCESS = 'access-surveys',
  SURVEYS_ADMIN = 'administer-surveys',

  ORGANIZATIONS_ADMIN = 'administer-organizations',

  FIELDS_SETTINGS_ADMIN = 'administer-fields-settings',
  CUSTOM_FIELDS_ADMIN = 'administer-custom-fields',

  JOURNEYS_ACCESS = 'view-journeys',
  JOURNEYS_ADMIN = 'manage-journeys',
  JOURNEY_MANAGE_USERS = 'manage-journey-users',

  ADMINISTER_PHONE_NUMBERS = 'administer-phone-numbers',

  VIEW_SSN = 'view-ssn',
  VIEW_SENT_NOTIFICATION_CONTENT = 'view-sent-notification-content',
  VIEW_SENT_NOTIFICATION_CONTENT_REDACTED = 'view-sent-notification-content-redacted',
  LOGIN_TO_APPLICATION = 'login-to-application',

  TERRITORIES_ADMIN = 'administer-territories',
  EDIT_TERRITORY_OPT_IN = 'edit-territory-opt-in',

  VIEW_DATA_QUALITY_ISSUES = 'view-data-quality-issues',

  APPOINTMENTS_ADMIN = 'administer-appointments',
  APPOINTMENTS_VIEW = 'view-appointments',
  APPOINTMENTS_ACCESS = 'access-appointments',
  APPOINTMENTS_ACCESS_MY = 'access-my-appointments',

  KNOWLEDGE_BASE_ADMIN = 'administer-knowledge-base',

  ADMINISTER_BILLING = 'administer-billing',

  BOLT_DISCOVERY_ADMIN = 'administer-bolt-discovery',
  BOLT_DISCOVERY_ACCESS = 'access-bolt-discovery',

  VIEW_ALL_CONVERSATIONS = 'view-all-conversations',
  VIEW_ALL_NOTES = 'can-see-all-notes',

  NETWORK_ROLES_ADMIN = 'administer-network-roles',
  NETWORK_CONNECTIONS_ADMIN = 'administer-network-connections',

  MEDIA_MANAGER_ADMIN = 'administer-media-manager',

  SFTP_MANAGEMENT_ADMIN = 'sftp-administrator',

  STUDENT_HUB_ADMINISTRATOR = 'student-hub-administrator',

  DELETE_USERS_IN_SEGMENT = 'delete-users-in-segment',

  PAYMENT_GATEWAYS_ADMINISTRATOR = 'payment-gateways-administrator',

  COURSES_ACCESS = 'access-courses',
  COURSES_ADMIN = 'administer-courses'
}

/**
 * All prebuilt shared dashboards
 */
export const ANY_INSIGHTS_DASHBOARD_PERMISSIONS = combinePermissions(
  PERMISSION.INSIGHTS_TASKS_DASHBOARD,
  PERMISSION.INSIGHTS_CAMPAIGNS_DASHBOARD,
  PERMISSION.INSIGHTS_EVENTS_DASHBOARD,
  PERMISSION.INSIGHTS_FUNNEL_DASHBOARD,
  PERMISSION.INSIGHTS_SOURCES_DASHBOARD,
  PERMISSION.INSIGHTS_CONVERSATIONS_DASHBOARD,
  PERMISSION.INSIGHTS_WEB_ACTIVITIES_DASHBOARD,
  PERMISSION.INSIGHTS_MANAGEMENT_DASHBOARD,
  PERMISSION.INSIGHTS_APPOINTMENTS_DASHBOARD,
  PERMISSION.INSIGHTS_DATA_HYGIENE_DASHBOARD,
  PERMISSION.INSIGHTS_PAYMENTS_DASHBOARD,
  PERMISSION.INSIGHTS_APPLICATIONS_DASHBOARD,
  PERMISSION.INSIGHTS_DISCOVERY_DASHBOARD
);
